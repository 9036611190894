<template>
  <div>
    <VaSidebar v-if="isMenuReady" v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="0">
      <VaAccordion v-model="value" multiple>
        <VaCollapse
          v-for="(route, index) in navigationRoutes.routes"
          :key="`route-${index}-${route.name}`"
          v-model="expandedItems[index]"
        >
          <!-- First Level (Main Route) -->
          <template #header="{ value: isCollapsed }">
            <VaSidebarItem
              :to="route.children ? undefined : { name: route.name }"
              :active="routeHasActiveChild(route)"
              :active-color="activeColor"
              :text-color="textColor(route)"
              :aria-label="`${route.children ? 'Open category ' : 'Visit'} ${t(route.displayName)}`"
              role="button"
              hover-opacity="0.10"
            >
              <VaSidebarItemContent class="py-3 pr-2 pl-4">
                <VaIcon
                  v-if="route.meta.icon"
                  aria-hidden="true"
                  :name="route.meta.icon"
                  size="20px"
                  :color="iconColor(route)"
                />
                <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                  {{ t(route.displayName) }}
                  <VaIcon v-if="route.children" :name="arrowDirection(isCollapsed)" size="20px" />
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </template>
          <!-- Second Level (Child Routes) -->
          <template #body>
            <VaCollapse
              v-for="(childRoute, index2) in route.children"
              :key="`child-${index2}-${childRoute.name}`"
              v-model="expandedItems[`child-${index}-${index2}`]"
            >
              <template #header="{ value: isChildCollapsed }">
                <VaSidebarItem
                  :to="childRoute.children ? undefined : { name: childRoute.name }"
                  :active="isActiveChildRoute(childRoute)"
                  :active-color="activeColor"
                  :text-color="textColor(childRoute)"
                  :aria-label="`Visit ${t(childRoute.displayName)}`"
                  hover-opacity="0.10"
                >
                  <VaSidebarItemContent class="py-3 pr-2 pl-11">
                    <VaSidebarItemTitle class="leading-5 font-semibold">
                      {{ t(childRoute.displayName) }}
                      <VaIcon v-if="childRoute.children" :name="arrowDirection(isChildCollapsed)" size="20px" />
                    </VaSidebarItemTitle>
                  </VaSidebarItemContent>
                </VaSidebarItem>
              </template>

              <!-- Third Level (Grandchild Routes) -->
              <template v-if="childRoute.children && childRoute.children.length" #body>
                <div
                  v-for="(grandChildRoute, index3) in childRoute.children"
                  :key="`grandchild-${index3}-${grandChildRoute.name}`"
                >
                  <VaSidebarItem
                    :to="{ name: grandChildRoute.name }"
                    :active="isActiveChildRoute(grandChildRoute)"
                    :active-color="activeColor"
                    :text-color="textColor(grandChildRoute)"
                    :aria-label="`Visit ${t(grandChildRoute.displayName)}`"
                    hover-opacity="0.10"
                  >
                    <VaSidebarItemContent class="py-3 pr-2 pl-16">
                      <VaSidebarItemTitle class="leading-5 font-semibold">
                        {{ t(grandChildRoute.displayName) }}
                      </VaSidebarItemTitle>
                    </VaSidebarItemContent>
                  </VaSidebarItem>
                </div>
              </template>
            </VaCollapse>
          </template>
        </VaCollapse>
      </VaAccordion>
    </VaSidebar>
    <div v-else>Loading menu...</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'

import navigationRoutes, { type INavigationRoute } from './NavigationRoutes'

export default defineComponent({
  name: 'Sidebar',
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible'],

  setup: (props, { emit }) => {
    const { getColor, colorToRgba } = useColors()
    const route = useRoute()
    const { t } = useI18n()

    const value = ref<boolean[]>([])
    const expandedItems = ref<Record<string, boolean>>({})

    const isMenuReady = ref(false)

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name

    const routeHasActiveChild = (section: INavigationRoute) => {
      if (!section.children) {
        return route.path.endsWith(`${section.name}`)
      }
      return section.children.some(({ name }) => route.path.endsWith(`${name}`))
    }

    const setActiveExpand = () => {
      value.value = navigationRoutes.routes.map((route: INavigationRoute) => routeHasActiveChild(route))
    }

    const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
    const color = computed(() => getColor('background-secondary'))
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'secondary')
    const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary')
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    const fetchMenuData = () => {
      const menu = localStorage.getItem('menu')
      console.log('Fetching menu from localStorage:', menu) // Debugging
      try {
        const parsedMenu = JSON.parse(menu || '[]') // Fallback to an empty array
        if (Array.isArray(parsedMenu)) {
          console.log('Menu is a direct array:', parsedMenu) // Debugging
          navigationRoutes.routes = parsedMenu // Assign directly if it's an array
          isMenuReady.value = true
        } else if (parsedMenu && Array.isArray(parsedMenu.routes)) {
          console.log('Menu contains routes array:', parsedMenu.routes) // Debugging
          navigationRoutes.routes = parsedMenu.routes // Extract routes from the object
          isMenuReady.value = true
        } else {
          console.error('Menu structure is not valid:', parsedMenu)
          isMenuReady.value = false
        }
      } catch (error) {
        console.error('Error parsing menu from localStorage:', error)
        isMenuReady.value = false
      }
    }

    const checkLocalStorageMenu = () => {
      const interval = setInterval(() => {
        fetchMenuData()
        if (isMenuReady.value) {
          console.log('Menu is ready, clearing interval') // Debugging
          clearInterval(interval)
        }
      }, 100)
    }

    watch(() => route.fullPath, setActiveExpand, { immediate: true })

    onMounted(() => {
      checkLocalStorageMenu() // Start checking when the component is mounted
    })

    return {
      writableVisible,
      sidebarWidth,
      value,
      expandedItems,
      color,
      activeColor,
      navigationRoutes,
      routeHasActiveChild,
      isActiveChildRoute,
      t,
      iconColor,
      textColor,
      arrowDirection,
      isMenuReady,
    }
  },
})
</script>
